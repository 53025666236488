import { Container, Row, Col, Card, } from "react-bootstrap"

import Bg1 from './../assets/images/Layanan/BgLayanan1.jpg'
import Bg2 from './../assets/images/Layanan/BgLayanan2.jpg'
import Bg3 from './../assets/images/Layanan/BgLayanan3.jpg'
import Bg4 from './../assets/images/Layanan/BgLayanan4.jpg'
import Bg5 from './../assets/images/Layanan/BgLayanan5.jpg'
import Bg6 from './../assets/images/Layanan/BgLayanan6.jpg'

const Layanan =() => {
    return (
        <div id="layanan"
        className="layanan"
        >
           <Container className="layoutLayanan1 ">
           <Row>
                    <Col className="technoLayout1">
                    <h1 className="technoBGLayanan">
                        LAYANAN
                    </h1>
                    </Col>
                    
            </Row>
            <Row xs={1} md={2} lg={2} xl={3} className="g-4">
                {Array.from({ length: 1 }).map((_, idx) => (
                    <Col className="layoutLayanan">
                    <Card style={{ width: '25rem'  }} >
                        <Card.Img variant="top" src={Bg1} alt="Layanan1" />
                        <Card.Body>
                            <Card.Title>Supply Parts Electrical Automation</Card.Title>
                        </Card.Body>
                    </Card>
                    </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                    <Col className="layoutLayanan">
                    <Card style={{ width: '25rem' }}>
                        <Card.Img variant="top" src={Bg5} alt="Layanan2"/>
                        <Card.Body>
                            <Card.Title>Retrofit Hardware & Automation System</Card.Title>
                        </Card.Body>
                    </Card>
                    </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                    <Col className="layoutLayanan">
                    <Card style={{ width: '25rem' }}>
                        <Card.Img variant="top" src={Bg2} alt="Layanan3" />
                        <Card.Body>
                            <Card.Title>New Automation</Card.Title>
                        </Card.Body>
                    </Card>
                    </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                    <Col className="layoutLayanan">
                    <Card style={{ width: '25rem' }}>
                        <Card.Img variant="top" src={Bg3} alt="Layanan4"/>
                        <Card.Body>
                            <Card.Title>Design</Card.Title>
                        </Card.Body>
                    </Card>
                    </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                    <Col className="layoutLayanan">
                    <Card style={{ width: '25rem' }}>
                        <Card.Img variant="top" src={Bg4} alt="Layanan5" />
                        <Card.Body>
                            <Card.Title>Mechanical</Card.Title>
                        </Card.Body>
                    </Card>
                    </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                    <Col className="layoutLayanan">
                    <Card style={{ width: '25rem' }}>
                        <Card.Img variant="top" src={Bg6} alt="Layanan6" />
                        <Card.Body>
                            <Card.Title>    IoT Integration</Card.Title>
                        </Card.Body>
                    </Card>
                    </Col>
                ))}
            </Row>
        </Container>
           
        </div>
        
    )
}

export default Layanan
