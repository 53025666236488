import { Navbar, Container, Nav, Offcanvas,  NavbarBrand, OffcanvasBody} from "react-bootstrap"
import bg4Image from "../assets/images/slideimg/bg4.jpg"
import AOS from "aos"
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";
import React from "react";
AOS.init();

const NavigationBar = () => {
  const [show, setShow] =useState(false)
  const controlNavigationBar = ()=> {
    if (window.scrollY>700) {
      setShow(true)
    }
    else{
      setShow(false)
    }
  }
  useEffect(()=>{
    window.addEventListener('scroll',
    controlNavigationBar)
    return()=>{
        window.removeEventListener('scroll',
        controlNavigationBar)
    }
  },[])
    return(
      <>
      {['md'].map((expand) =>(
    <div >
      
      <Navbar key={expand}  expand={expand} fixed='top' className={ `nav ${show && 'navbar-scroll' }`} >
     
        <Container  > 
        
          <NavbarBrand href="#home" className="title top2"><img className="titleImage " src={bg4Image} alt=""/></NavbarBrand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              bg="light"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  
                </Offcanvas.Title >
              </Offcanvas.Header >
                <OffcanvasBody className="top">
                  <Nav className="justify-content-end flex-grow-1 option transition-swipe title" >
                    <Nav.Link href="#home">HOME</Nav.Link>
                    <Nav.Link href="#about">Tentang Kami</Nav.Link>
                    <Nav.Link href="#produk">Produk</Nav.Link>
                    <Nav.Link href="#layanan">Layanan</Nav.Link>
                    <Nav.Link href="#partner">Partner</Nav.Link>
                    <Nav.Link href="#footer">Hubungi Kami</Nav.Link>
                  </Nav>
                </OffcanvasBody>
              </Navbar.Offcanvas>
             
        </Container>
        
      </Navbar>
      
    </div>
      ))}
    </>
    ) 
}

export default NavigationBar