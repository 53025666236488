import { Container, Row, Col, Card, ListGroup } from "react-bootstrap"
import AOS from "aos"
import 'aos/dist/aos.css';

AOS.init();


const About =() => {
    return (
        
        
        <div id="produk"
        className="technoBG"
        >
          <Container className="layoutProduk1" >
          <Row>
                    <Col className="technoLayout1">
                    <h1 className="produkBGColor " data-aos="fade-up">
                        PRODUK
                    </h1>
                    </Col>
                    
            </Row>
          <Row xs={1} md={1} lg={2} xl={4} className="g-4">
                {Array.from({ length: 1}).map((_, idx) => (
                <Col className="layoutProduk" >
                    <Card style={{ width: '18rem' }} data-aos="fade-up">
                        <Card.Header  as="h5" className="bg-dark text-white">ELECTRICAL CONTROL & MOTION</Card.Header>
                            <ListGroup variant="flush" as="h6">
                                <ListGroup.Item >MITSUBISHI</ListGroup.Item>
                                <ListGroup.Item >BECKHOFF</ListGroup.Item>
                                <ListGroup.Item >OMRON</ListGroup.Item>
                                <ListGroup.Item >YASKAWA</ListGroup.Item>
                                <ListGroup.Item >SIEMENS</ListGroup.Item>
                                <ListGroup.Item >ALLEN-BREDLEY</ListGroup.Item>
                                <ListGroup.Item >SCHNEIDER</ListGroup.Item>
                                <ListGroup.Item >DELTA</ListGroup.Item>
                                <ListGroup.Item >WEINNTEK</ListGroup.Item>
                                <ListGroup.Item >ORIENTAL MOTOR</ListGroup.Item>
                                <ListGroup.Item >SESAME MOTOR</ListGroup.Item>
                            </ListGroup>
                    </Card>
                </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                <Col className="layoutProduk">
                    <Card style={{ width: '18rem' }} data-aos="fade-up">
                        <Card.Header as="h5" className="bg-dark text-white">LINIER MOTION & BEARING</Card.Header>
                            <ListGroup variant="flush" as="h6">
                                <ListGroup.Item>THK</ListGroup.Item>
                                <ListGroup.Item>INA</ListGroup.Item>
                                <ListGroup.Item>KHK</ListGroup.Item>
                                <ListGroup.Item>IKO</ListGroup.Item>
                                <ListGroup.Item>NSK</ListGroup.Item>
                                <ListGroup.Item>NB</ListGroup.Item>
                                <ListGroup.Item>TSUBAKI</ListGroup.Item>
                                <ListGroup.Item>SAMICK</ListGroup.Item>
                                <ListGroup.Item>HIWIN</ListGroup.Item>
                            </ListGroup>
                    </Card>
                </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                <Col className="layoutProduk">
                    <Card style={{ width: '18rem' }} data-aos="fade-up">
                        <Card.Header as="h5" className="bg-dark text-white">SENSOR</Card.Header>
                            <ListGroup variant="flush" as="h6">
                                <ListGroup.Item>CONTRINEX</ListGroup.Item>
                                <ListGroup.Item>PEPPERL + FUCHS</ListGroup.Item>
                                <ListGroup.Item>BAUMER</ListGroup.Item>
                                <ListGroup.Item>SICK</ListGroup.Item>
                                <ListGroup.Item>KEYANCE</ListGroup.Item>
                                <ListGroup.Item>OMRON</ListGroup.Item>
                                <ListGroup.Item>YAMATAKE</ListGroup.Item>
                            </ListGroup>
                    </Card>
                </Col>
                ))}
                {Array.from({ length: 1 }).map((_, idx) => (
                <Col className="layoutProduk">
                    <Card style={{ width: '18rem' }} data-aos="fade-up">
                        <Card.Header as="h5" className="bg-dark text-white">PNEUMATIC & HIDROLIC</Card.Header>
                            <ListGroup variant="flush" as="h6">
                                <ListGroup.Item>SMC</ListGroup.Item>
                                <ListGroup.Item>CKD</ListGroup.Item>
                                <ListGroup.Item>KOGANEI</ListGroup.Item>
                                <ListGroup.Item>AVENTICS</ListGroup.Item>
                                <ListGroup.Item>REXROTH</ListGroup.Item>
                            </ListGroup>
                    </Card>
                </Col>
                ))}
            </Row>
        </Container>
        </div>
        
    )
}


export default About
