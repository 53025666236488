import { Carousel} from "react-bootstrap"
import bgi1 from './../assets/images/katalog/1.avif'
import bgi2 from './../assets/images/katalog/2.avif'
import bgi3 from './../assets/images/katalog/3.avif'
import AOS from "aos"
AOS.init();

const Intro = () => {
    return(
        
        <div id="home" 
        className=""
        >
            
                    <Carousel data-aos="fade-in" className="" > 
                                <Carousel.Item className="editpage1">
                                    <img
                                    className="d-block w-100 intro"
                                    src= {bgi1}
                                    alt="First slide"
                                    />
                                    <Carousel.Caption >
                                        <h3 className="fontpage2">PT KAIROS SOLUSI INDONESIA</h3>
                                        <p className="fontpage1">SOLUTION FOR YOUR INDUSTRIAL NEED</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                
                                <Carousel.Item className="editpage1">
                                    <img
                                    className="d-block w-100 intro"
                                    src={bgi2}
                                    alt="Second slide"
                                    />
                                    <Carousel.Caption >
                                        <h3 className="fontpage2">PT KAIROS SOLUSI INDONESIA</h3>
                                        <p className="fontpage1">SOLUTION FOR YOUR INDUSTRIAL NEED</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item className="editpage1">
                                    <img
                                    className="d-block w-100 intro"
                                    src={bgi3}
                                    alt="Third slide"
                                    />
                                    <Carousel.Caption >
                                        <h3 className="fontpage2">PT KAIROS SOLUSI INDONESIA</h3>
                                        <p className="fontpage1">SOLUTION FOR YOUR INDUSTRIAL NEED</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                        </Carousel>     
                    
               

                        
                            
       </div>
           
    )
}

export default Intro