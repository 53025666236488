import Slider from "react-slick";
import React from "react";
import {  Card, Row,Col} from "react-bootstrap"
import Bgp1 from './../assets/images/partner/1.png'
import Bgp2 from './../assets/images/partner/2.png'
import Bgp3 from './../assets/images/partner/3.png'
import Bgp4 from './../assets/images/partner/4.png'
import Bgp5 from './../assets/images/partner/5.png'
import Bgp6 from './../assets/images/partner/6.png'
import Bgp7 from './../assets/images/partner/7.png'
import Bgp8 from './../assets/images/partner/8.png'
import AOS from "aos"
AOS.init();

export default function Techno() {

    const settings = {
      dots:true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2500,
      autoplaySpeed: 2500,
      initialSlide: 0,
      cssEase: "linear",
      responsive:
       [
        {
          breakpoint: 1424,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1124,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        
      ]
      
      };
      return (
        <div id="partner" className="technoBG" >
      <div className="technoMargin">
              <Row>
                    <Col className="technoLayout1">
                    <h1 className="technoBGColor">
                        PARTNER
                    </h1>
                    </Col>
                    
            </Row>
      <Slider {...settings} >
        <div className="technoBG">
        <Card style={{ width: '15rem' }} className="technoLayout" >
                      <Card.Img variant="top" src={Bgp1} />
                      
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }} className="technoLayout" >
                      <Card.Img variant="top" src={Bgp5} />
                      
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }} className="technoLayout" >
                      <Card.Img variant="top" src={Bgp2} />
                      
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }} className="technoLayout" >
                      <Card.Img variant="top" src={Bgp3} />
                     
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }}className="technoLayout">
                      <Card.Img variant="top" src={Bgp4} />
                      
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }} className="technoLayout">
                      <Card.Img variant="top" src={Bgp6} />
                     
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }} className="technoLayout">
                      <Card.Img variant="top" src={Bgp7} />
                     
                  </Card>
        </div>
        <div>
        <Card style={{ width: '15rem' }} className="technoLayout">
                      <Card.Img variant="top" src={Bgp8} />
                     
                  </Card>
        </div>
                 
      </Slider>
     
    </div>
    </div>
           
    );
}

