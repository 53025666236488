import { Container, Row, Col  } from "react-bootstrap"
import React from "react"

const End =() => {
    return (
        <div id=""
        className="endFooter"
        >
           <Container className="endFooter1">
           <Row>
                    <Col className="">
                    <h1 className="endFooter2">
                        &copy;2023 KSI. All rights reserved
                    </h1>
                    </Col>
            </Row>
        </Container>
        </div>
        
    )
}

export default End