
import './App.css';
import NavigationBar from "./component/NavigationBar"
import Intro from "./component/Intro"
import About from './component/About';
import Techno from './component/Techno';
import "./style/LandingPage.css"
import Bottom from './component/Bottom';
import Footer from './component/Footer';
import Layanan from './component/Layanan';
import Produk from './component/Produk';



function App() {
  return (
    <div>
      <div >
        <NavigationBar/>
        <Intro/>
      </div>
      <div >
        <About/>
        <Produk/>
        <Layanan/>
      </div>
      <div >
      <Techno/>
      </div>
      <div >
        <Footer/>
        <Bottom/>
      </div>
    </div>
  );
}

export default App;
