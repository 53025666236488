import { Container, Row, Col,Nav} from "react-bootstrap"

const Footer = () => {
    return(
        <div id="footer" className="end ">
            <Container className="layoutFooter1">
                <Row xs={1} md={2} lg={2} xl={2} className="g-4">
                 {Array.from({length :1}).map((_, idx)=>(
                <Col className="LayoutFooter"  >
                <h1 className="TitleFooter ">
                   Kontak
                </h1>
                <h2 className="Text3">
                    Star Safira Blok B4 No 1B, Suko, Sukodono, Sidoarjo
                    </h2>
                    <Nav.Link href="mailto:qnkairos@gmail.com" >
                    <h2 className="Text2">
                        Email : sales@kairossolusi.co.id
                    </h2>
                    </Nav.Link>
                    <h2 className="Text3">
                        Phone  : 031-58200700
                    </h2>
                    <Nav.Link href="https://alvo.chat/1jtZ" >
                    <h2 className="Text2">
                        Hotline : +62 81130023000
                    </h2>
                    </Nav.Link>
                </Col>
                 ))}
                   {Array.from({length :1}).map((_, idx)=>(
                <Col className="LayoutFooter" >
                <h1 className="TitleFooter">
                    G-Maps
                </h1>
                <iframe   style={{width:"400px",height:"400px"}}src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d294.0862412979839!2d112.69820825275602!3d-7.383336208819479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7e370efed28df%3A0xf2342715cf0460e6!2sStar%20Safira%20Regency!5e0!3m2!1sid!2sid!4v1679890675420!5m2!1sid!2sid" title="Alamat Kairos Solusi Indoneisa">
                </iframe>
                </Col>
                ))}
                </Row>
            </Container>
                
                            
       </div>
           
        
    )
}

export default Footer