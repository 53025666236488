import { Container, Row, Col } from "react-bootstrap"
import AOS from "aos"
import 'aos/dist/aos.css';
import {Typography, Stack} from "@mui/material"
import {
    createTheme, responsiveFontSizes,  ThemeProvider
} from "@mui/material/styles";

AOS.init();
let theme = createTheme();
theme = responsiveFontSizes(theme);


const About =() => {
    return (
        
        
        <div id="about"
        className="about"
        >
           <Container className="layoutAbout">
            <Row>
                <Col >
                <ThemeProvider theme={theme }>
                        <Stack direction= 'column' spacing={1} >
                        <Typography variant="h4" data-aos="fade-up " >
                        TENTANG KAMI 
                        </Typography>
                        <Typography variant="h6"data-aos="fade-up " >
                        PT Kairos Solusi Indonesia adalah perusahaan yang menyediakan dan memberikan solusi terbaik terhadap kebutuhan Automation dan Industri dengan Product dari merek - merek Internasional yang sudah teruji kualitas dan teknologinya. 
                        </Typography>
                        <Typography variant="h6" data-aos="fade-up ">
                        Product utama kami meliputi PLC, Human Machine Interface, Servo, Inverter, Sensor, Motor, Pneumatic & Vacuum Component dan kebutuhan Industri lainnya. Selain itu untuk menjawab kebutuhan akan kemajuan teknologi yang terus berkembang PT Kairos Solusi indonesia juga melayani Design, Retrofit, Peremajaan Hardware & System Automation, serta IoT Integration. 
                        </Typography> 
                        </Stack>
                </ThemeProvider>
                </Col>
                <Col>
                <ThemeProvider theme={theme } >
                        <Stack direction= 'column' spacing={1} >
                        <Typography variant="h4" data-aos="fade-up " >
                        VISI :
                        </Typography>
                        <Typography variant="h6"data-aos="fade-up " >
                        - Menjadi sebuah barometer solusi untuk kemajuan teknologi manufactur di Indonesia.
                        </Typography>
                        <Typography variant="h4" data-aos="fade-up " >
                        MISI :
                        </Typography>
                        <Typography variant="h6" data-aos="fade-up ">
                        - Untuk menciptakan kepuasan pelanggan tertinggi dengan layanan solusi, kompetensi dan 
                            fleksibilitas.
                        </Typography> 
                        <Typography variant="h6" data-aos="fade-up ">
                        - Mempunyai dampak dan pengaruh positif bagi lingkungan kerja dan sekitar.
                        </Typography>
                        <Typography variant="h6" data-aos="fade-up ">
                        - Berkomitmen memberikan support dan solusi terbaik untuk setiap kebutuhan Industri 
                        anda tanpa mengurangi Mutu dan Kualitas, dengan harga yang Kompetitif.
                        </Typography>
                          </Stack>
                </ThemeProvider>
                </Col>
                
        
            </Row>
        </Container>
           
        </div>
        
    )
}


export default About
